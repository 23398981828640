<template>
  <!-- <v-data-table
    single-expand
    show-expand
    must-sort
    :loading="loading"
    :headers="headers"
    :items="indications"
    item-key="uuid"
    :options.sync="options"
    :server-items-length="totalItems"
    :footer-props="{
      itemsPerPageOptions: [ 15, 50, 100, 200 ],
    }"
    class="elevation-2"
  > -->
  <v-data-table
    must-sort
    fixed-header
    :headers="headers"
    :items="indications"
    :search="options.search"
    :footer-props="{
      itemsPerPageOptions: [ 15, 50, 100, 200 ],
      itemsPerPageText: `${ $options.filters.capitalize($tc('indication', 2)) } ${ $t('per page') }:`,
      showFirstLastPage: true
    }"
    sort-by="indication"
    class="elevation-2"
  >
    <template #top>
      <v-row
        justify="space-between"
        class="pt-3 px-3 mb-n3 "
      >
        <v-col md="3">
          <search-bar :value.sync="options.search" />
        </v-col>
        <v-spacer />
        <v-col md="2">
          <source-filter
            :value.sync="options.filter.source"
          />
        </v-col>
        <v-col class="shrink">
          <v-btn
            v-if="$auth.check({ indications: 'edit' })"
            color="success"
            fab
            small
            class="mt-n5"
            @click="$emit('toggle-right-drawer', {
              sources: [],
              organism_categories: [],
              diagnosis_groups: []
            }, true)"
          >
            <v-icon small>
              fal fa-plus
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </template>
    <template #item.sources="{ item }">
      <span
        v-for="source in item.sources"
        :key="source"
        :source="source"
        class="mr-1"
      >
        <source-icon
          :source="source"
          :size="18"
        />
      </span>
    </template>
    <template #item.organism_categories="{ item }">
      <v-chip
        v-for="category in item.organism_categories.slice(0, 3)"
        :key="category"
        :color="stringColor(category.name)"
        class="mr-1"
        small
        v-text="category.name"
      />
      <v-chip
        v-if="item.organism_categories.length > 3"
        small
      >
        <v-icon
          class="ml-n2 mr-1"
          x-small
        >
          fal fa-plus-circle
        </v-icon>
        {{ item.organism_categories.length - 3 }} more
      </v-chip>
    </template>
    <template #item.diagnosis_groups="{ item }">
      <v-chip
        v-for="group in item.diagnosis_groups.slice(0, 3)"
        :key="group"
        :color="stringColor(group.name)"
        class="mr-1"
        small
        v-text="group.name"
      />
      <v-chip
        v-if="item.diagnosis_groups.length > 3"
        small
      >
        <v-icon
          class="ml-n2 mr-1"
          x-small
        >
          fal fa-plus-circle
        </v-icon>
        {{ item.diagnosis_groups.length - 3 }} more
      </v-chip>
    </template>
    <template #item.actions="{ item }">
      <v-btn
        v-if="$auth.check({ indications: 'edit' })"
        small
        ripple
        icon
        plain
        @click="$emit('toggle-right-drawer', item, true)"
      >
        <v-icon>fal fa-ellipsis-v</v-icon>
      </v-btn>
    </template>
  </v-data-table>
</template>
<script>
  export default {
    components: {
      SearchBar: () => import('@/components/tables/filters/SearchBar.vue'),
      SourceFilter: () => import('@/components/tables/filters/SourceFilter.vue'),
      SourceIcon: () => import('@/components/source/SourceIcon.vue'),
    },
    props: {
      search: {
        type: String,
        default: null,
      },
    },
    metaInfo: {
      title: 'Indications',
    },
    data () {
      return {
        loading: false,
        options: {
          search: null,
          filter: this.$route.query,
          sortBy: ['indication'],
          sortDesc: [false],
        },
        totalItems: null,
        headers: [
          {
            text: 'Indication',
            value: 'indication.en',
          },
          {
            text: 'Internal',
            value: 'internal_name',
          },
          {
            text: 'Sources',
            value: 'sources',
          },
          {
            text: 'Organism Categories',
            value: 'organism_categories',
          },
          {
            text: 'Diagnosis Groups',
            value: 'diagnosis_groups',
          },
          {
            sortable: false,
            align: 'right',
            value: 'actions',
          },
        ],
        indicationForm: {},
        indicationEditDialog: false,
        indicationDetails: [],
      }
    },
    computed: {
      indications () {
        return this.$store.getters.getIndications
      },
    },
    watch: {

      options: {
        deep: true,
        handler () {
        //   this.fetchIndications()
        //   this.$store.dispatch("fetchIndications", { self: this })
        },
      },
    },
    mounted() {
      this.$root.$on('fetch-indications', () => {
        this.$store.dispatch("fetchIndications", { self: this })
      })
    },
    methods: {
      fetchIndications () {
        this.loading = true
        const promise = this.axios.get('admin/indications', {
          params:
            {
              ...{
              page: this.options.page,
              count: this.options.itemsPerPage,
              sort: this.options.sortBy[0],
              order: this.options.sortDesc[0] ? 'desc' : 'asc',
              search: this.options.search,
            },
              ...this.$route.query,
            },
        })

        return promise.then((response) => {
        //   this.totalItems = response.data.total
        //   this.options.page = response.data.current_page
        //   this.options.itemsPerPage = Number(response.data.per_page)
          this.indications = response.data
        }).catch(error => {
          if (error.response?.data?.message) {
            this.$toast.error(error.response.data.message)
          }
        }).finally(() => {
          this.loading = false
        })
      },
    },
  }
</script>
